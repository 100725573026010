@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Lyric board */
  .highlight-chord {
    @apply font-bold text-xs text-white bg-orange-400 inline-block px-[4px] py-[2px] leading-[1.1] rounded shadow mr-[18px];
  }

  /* Form elements */
  .chord-input {
    @apply bg-orange-100 border w-full p-1 border-rose-300;
  }

  .lyric-input {
    @apply bg-gray-100 rounded-lg shadow focus:shadow-md border w-full p-1 border-gray-300;
  }

  .primary-input {
    @apply bg-white rounded-lg shadow focus:shadow-md border border-orange-400 p-2 focus:outline-none focus:border-orange-500 text-dark;
  }

  /* Buttons */
  .primary-btn--info {
    @apply rounded-md py-2 px-4 flex justify-center items-center gap-2 font-secondary shadow-md  bg-info text-white;
  }

  .primary-btn--info-outline {
    @apply rounded-md py-2 px-4 flex justify-center items-center gap-2 font-secondary shadow-md  bg-white border border-info text-info;
  }  

  /* Cards */
  .listing-card {
    @apply w-full p-1 lg:w-auto shadow font-secondary rounded-md min-w-[180px];
  }
}

.lyric-bg {
  background-color: #ffffff;
  background-image: url("https://www.transparenttextures.com/patterns/natural-paper.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.auth-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
