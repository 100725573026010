* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

input:focus {
  outline: none;
}

td {
  vertical-align: top;
}

.lyric-input {
  line-height: 1.5;
  /* word-spacing: 3.4px; */
}

.lyric-board {
  line-height: 1;
  /* word-spacing: 2.2px; */
}

.gen-form input {
  line-height: 1.5;
  word-spacing: 3.3px;
}

.lyric-genBtn {
  display: table;
  padding: 15px;
  margin: auto;
}

.detected-chords {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-weight: bold;
}

.transposed-chords {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-weight: bold;
}

.transposer {
  background-color: aquamarine;
  padding-bottom: 10px;
}

.transposer .transpose-btn {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.chord {
  display: inline-block;
  background-color: brown;
  color: white;
  padding: 2px;
}

.lyric-line {
  margin: 10px 0 5px 0;
  white-space: pre-wrap;
}

.chord-line {
  background-color: antiquewhite;
}

.gui-form input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.062) 0px 1px 3px -1px;
  border-radius: 5px;
  padding: 10px;
}

.user-profile {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}

.board-listing--grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

@media screen and (max-width: 1180px) {
  .board-listing--grid {
    overflow-x: scroll;
    display: grid;
    grid-template-columns: repeat(5, 230px);
  }
}

.listing-card {
  background-position: 100%;
  background-size: 400%;
  background-color: white;
  background-image: linear-gradient(45deg, #f6d365, #fda085 30%, white 60%);
  transition: background-position 0.9s cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
}

.listing-card:hover {
  background-position: 0;
}

.listing-card:hover h1{
  color: #f57c00;
}

/* MUI - overwrite */
/* .MuiOutlinedInput-notchedOutline */
.MuiInputLabel-root {
  color: #aaaaaa !important;
  font-size: 16px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ffa726 !important;
}

.MuiButtonBase-root:hover {
  background-color: #f57c00 !important;
}

/* Chord Board */
.chordBoard g circle[r="4"] {
  fill: #00BFE8;
  stroke: #00BFE8;

}

.chordBoard g circle[r="2"] {
  fill: #00C09C;
  stroke: #00C09C;

}

.chordBoard g text[y="-2"] {
  fill: #F25259;
}

.chordBoard g text[y="31.5"],.chordBoard g text[y="19.5"],.chordBoard g text[y="8"],.chordBoard g text[y="43.5"]  {
  fill: #00BFE8;
}

.chordBoard rect {
  fill: #00BFE8;
}

.chordBoard .chord-item {
  width: 100px;
}

@media screen and (max-width : 768px) {
  .chordBoard .chord-item {
    width: 60px;
  }
}

text[font-family="Verdana"] {
  font-family: 'Rubik', sans-serif !important;
}

.presentChord .swiper {
  padding-bottom: 30px !important;
}

.presentChord .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0px !important;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  background: #f57c00 !important;
}
